
form[name=module-switch-form] {
  display: flex;
}
form[name=module-switch-form] > div {
  display: flex;
  margin-bottom: 0;
  flex-grow:1;
}
form[name=module-switch-form] > div > label {
  margin-right:10px;
  line-height: 36px;
  margin-bottom: 0px;
}
form[name=module-switch-form] > div > div {
  flex-grow:1;
}
form[name=module-switch-form] > button {
  margin-left:10px;
}


.generate-success .fa {
  font-size: 1.3em;
}
.generate-success .panel-body > .row > .col > .row {
  border-bottom: 1px solid #CCC;
  padding-top: 2px;
  padding-bottom: 2px;
}
.generate-success .panel-body > .row > .col > .row:first-child {
  margin-top:-10px;
}
.generate-success .panel-body > .row > .col > .row:last-child {
  border-bottom: none;
  margin-bottom:-10px;
}


.generate-progress .message {
  border:0px;
}
.generate-progress .progress {
  height: 30px;
  position: relative;
}
.generate-progress .progress .progress-text {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 6px;
}
  


.generate-success .fa[data-v-7f4f7aef] {
  font-size: 1.3em;
}
.supportedFileTypes .panel-body .col[data-v-7f4f7aef] {
  white-space: nowrap;
}
.upload.progress[data-v-7f4f7aef] {
  position: absolute;
  left: 15px;
  top: 0px;
  right: 15px;
  bottom: 0px;
  height: auto;
  z-index: 1;
}
.upload.progress .progress-text[data-v-7f4f7aef] {
  position: absolute;
  color: #FFF;
  width: 100%;
  text-align: center;
  top: 10px;
}
.ibox-content.sk-loading[data-v-7f4f7aef]:after {
  z-index:1;
}

